<template>
    <div class="ex_panel_tfair">
        <b-breadcrumb class="custom-bread">
            <b-breadcrumb-item href="#">Trade Fair Panel</b-breadcrumb-item>
            <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
        </b-breadcrumb>
        <b-row>
            <b-col>
                <b-card-title class="text-center"><h5>{{ $t('externalTradeFair.international_trade_fair_dashboard')}}</h5></b-card-title>
                <div v-if="hasPendingEvaluation" class="box-message col-md-12 text-danger text-center">
                    <div class="message-body message-body-center">
                        <p class="h6">
                            <marquee width="100%" direction="left">
                                <i class="ri-alert-fill mr-2"></i> {{ $i18n.locale === 'en' ? 'Please complete the previous fair participation evaluation.' : 'অনুগ্রহপূর্বক পূর্ববর্তী মেলায় অংশগ্রহণের মূল্যায়ন সম্পূর্ণ করুন।' }}
                                <span v-if="$i18n.locale === 'en'"><router-link to="fair-evaluation">{{ 'Click here' }}</router-link>{{ ' to evaluate.' }}</span>
                                <span v-else>{{ 'মূল্যায়নের জন্য ' }} <router-link to="fair-evaluation">{{ 'এখানে ক্লিক করুন।' }}</router-link></span>
                            </marquee>
                        </p>
                    </div>
                </div>
                <b-card class="mt-1">
                    <b-row>
                        <b-col sm="4">
                            <b-form-group
                                label-for="fiscal_year_id"
                                >
                                <template v-slot:label>
                                    {{ $t('tradeFairConfig.fiscal_year') }}
                                </template>
                                <b-form-select
                                    plain
                                    id="fiscal_year_id"
                                    :options="fiscalYearList"
                                    v-model="search.fiscal_year"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group
                                label-for="month"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.month') }}
                                </template>
                                <b-form-select
                                    plain
                                    id="month"
                                    :options="monthList"
                                    v-model="search.month"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group
                                label-for="fair_sector_id"
                                >
                                <template v-slot:label>
                                    {{ $t('tradeFairConfig.fair_sector') }}
                                </template>
                                <b-form-select
                                    plain
                                    id="fair_sector_id"
                                    :options="fairSectorList"
                                    v-model="search.fair_sector"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="table-wrapper">
                        <b-overlay :show="loadingState">
                        <b-table head-variant="custom" :tbody-tr-class="{ 'active-circular': isCircular }" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sort-icon-left
                             hover bordered responsive :emptyText="$t('globalTrans.noDataFound')" show-empty>
                             <template v-slot:cell(sl)="data">
                                <span>{{ $n(data.item.sl) }}</span>
                            </template>
                             <template v-slot:cell(duration)="data">
                                <span>{{ getDuration(data.item) }}</span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <b-button class="mb-1" :title="$t('globalTrans.details')" v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                                <b-button v-if="data.item.deadline >= current_date" class="mb-1 btn btn-info btn-sm ml-1" type="button" @click="apply(data.item)" :title="$t('globalTrans.apply')">{{ $t('globalTrans.apply') }}</b-button>
                            </template>
                        </b-table>
                        </b-overlay>
                    </div>
                </b-card>
                <b-modal id="modal-4" size="lg" :title="$t('externalTradeFair.circular_fair_participation') + ' ' + $t('globalTrans.details')" hide-footer ok-only ok-variant="danger">
                    <template #modal-title class="flex-grow-1">
                        {{ $t('externalTradeFair.circular_fair_participation') + ' ' + $t('globalTrans.details') }}
                        <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
                        {{  $t('globalTrans.export_pdf') }}
                        </b-button>
                    </template>
                    <Details :id="detailsItemId" :item="item" :key="detailsItemId" ref="details"/>
                </b-modal>
            </b-col>
        </b-row>
        <!-- *********upcoming fair & calender********* -->
        <b-row>
            <b-col sm="8">
                <b-card class="upcoming-fair">
                    <div class="d-flex justifiy-center align-items-center flex-column">
                        <div class="icon mb-2">
                            <img class="img-fluid" src="../../../../../../assets/images/local-shop.png" alt="image">
                        </div>
                        <div v-if="dataLoaded" class="content">
                            <h5>{{$i18n.locale === "bn" ? upComingvents?.calendar_info?.fair_name_bn : upComingvents?.calendar_info?.fair_name }}</h5>
                            <h6 class="py-3"><strong>{{$t('externalTradeFair.city_and_country') + ' : '}}</strong>{{($i18n.locale === "bn" ? upComingvents.calendar_info?.city_bn : upComingvents.calendar_info?.city) + ',' + getCountryName(upComingvents.calendar_info.country_id) }} </h6>
                            <p class="fair-date"><strong>{{$t('externalTradeFair.duration') + ' : '}}</strong>{{ getDuration(upComingvents) }}</p>
                        </div>
                        <div v-else class="content">
                            <h5>{{$t('externalTradeFair.no_upcoming_fair')}}</h5>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col sm="4">
                <b-card>
                    <div class="text-center">
                    <b-calendar
                        v-model='selected_date'
                        @context="onContext"
                        :hide-header="hideHeader"
                        :show-decade-nav="showDecadeNav"
                        locale="en-US">
                    </b-calendar>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Details from './fair-participation-circular/Details.vue'
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { itfDashBoardCircularListApi, itfDashBoardUpcomingEventApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
const today = new Date().toISOString().substr(0, 10)
export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
            current_date: today,
            value: '',
            context: null,
            showDecadeNav: false,
            hideHeader: true,
            sortBy: 'division',
            sortDesc: false,
            isCircular: true,
            dataLoaded: false,
            selected_date: '',
            data: {
                fiscal_year_id: 0,
                month: 0,
                fair_sector_id: 0
            },
            search: {
                fiscal_year: 0,
                month: 0,
                fair_sector: 0
            },
            items: [],
            upComingvents: {},
            item: '',
            detailsItemId: '',
            hasPendingEvaluation: false
        }
    },
    created () {
        this.search.fiscal_year = this.$store.state.currentFiscalYearId
        // this.loadData()
        this.upComingEventData()
    },
    watch: {
        'search.fiscal_year': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadData(newVal)
            }
        },
        'search.month': function (newVal, oldVal) {
            if (newVal !== oldVal) {
               this.loadData(newVal)
            }
        },
        'search.fair_sector': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadData(newVal)
            }
        }
    },
    computed: {
        fields () {
            if (this.$i18n.locale === 'en') {
                return [
                    { key: 'sl', label: this.$t('globalTrans.sl_no'), sortable: true },
                    { key: 'fair_name_en', label: this.$t('tradeFairConfig.fair_name'), sortable: true },
                    { key: 'city_and_country_en', label: this.$t('externalTradeFair.city_and_country'), sortable: true },
                    { key: 'duration', label: this.$t('externalTradeFair.duration'), sortable: true },
                    { key: 'action', sortable: false }
                ]
            } else {
                return [
                    { key: 'sl', label: this.$t('globalTrans.sl_no'), sortable: true },
                    { key: 'fair_name_bn', label: this.$t('tradeFairConfig.fair_name'), sortable: true },
                    { key: 'city_and_country_bn', label: this.$t('externalTradeFair.city_and_country'), sortable: true },
                    { key: 'duration', label: this.$t('externalTradeFair.duration'), sortable: true },
                    { key: 'action', sortable: false }
                ]
            }
        },
        fiscalYearList () {
            return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
        },
        fairSectorList () {
            return this.$store.state.ExternalUserService.tradeFair.commonObj.fairSectorList.filter(item => item.status === 1)
        },
        monthList () {
            return this.$store.state.commonObj.monthList.map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
            })
        }
    },
    methods: {
        onContext (ctx) {
            this.context = ctx
        },
        detailsData (item) {
            this.detailsItemId = item.id
            this.item = item
        },
        apply (item) {
            this.$router.push(`/trade-fair-panel/fair-participation-form?circular_id=${item.id}`).catch(() => {})
        },
        async loadData () {
            const params = Object.assign({}, this.search)
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, itfDashBoardCircularListApi, params)
            if (result.success) {
                this.hasPendingEvaluation = result.has_pending_evaluation
                if (result.data.length) {
                    this.items = result.data.map((item, index) => {
                        const country = this.getCountryName(item.calendar_info.country_id, true)
                        const newObj = {
                            isActive: true,
                            sl: index + 1,
                            fair_name_en: item.calendar_info.fair_name,
                            fair_name_bn: item.calendar_info.fair_name_bn,
                            city_and_country_en: item.calendar_info.city + ',' + (typeof country !== 'undefined' ? country.text_en : ''),
                            city_and_country_bn: item.calendar_info.city_bn + ',' + (typeof country !== 'undefined' ? country.text_bn : ''),
                            duration: '',
                            action: ''
                        }
                        return Object.assign({}, item, newObj)
                    })
                    this.$store.dispatch('setList', this.items)
                } else {
                    this.items = []
                    this.$store.dispatch('setList', [])
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        },
        getDuration (item) {
            // const deadline = item.deadline.split('-')
            const startDate = item.calendar_info.date_from.split('-')
            const endDate = item.calendar_info.date_to.split('-')
            // let deadlineId = ''
            let startMonthId = ''
            let endMonthId = ''
            // deadlineId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(deadline[1]))
            startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
            endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
            // item.deadline = this.$n(deadline[2]) + ' ' + (this.$i18n.locale === 'en' ? deadlineId.text_en : deadlineId.text_bn) + ', ' + this.$n(deadline[0], { useGrouping: false })
            let customDate = ''
            if (startDate[1] === endDate[1]) {
                customDate = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
            } else {
                customDate = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
            }
            return customDate
        },
        getCountryName (Id, needObj = false) {
            const country = this.$store.state.CommonService.commonObj.countryList.find(obj => obj.value === parseInt(Id))
            if (typeof country !== 'undefined') {
                if (needObj) {
                    return country
                }
                return this.$i18n.locale === 'en' ? country.text_en : country.text_bn
            } else {
                return ''
            }
        },
        async upComingEventData () {
            this.dataLoaded = false
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, itfDashBoardUpcomingEventApi)
            if (result.success) {
                this.dataLoaded = true
                this.upComingvents = result.data
                this.selected_date = this.upComingvents.calendar_info.date_from
            } else {
                this.dataLoaded = false
                this.selected_date = ''
            }
        },
        pdfExport () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
<style>
.b-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-help {
    display: none !important;
}
.b-calendar .b-calendar-inner .b-calendar-nav {
    display: none !important;
}
.ex_panel_tfair thead.thead-custom {
    color: #fff;
    background: #77a0cb;
}
.ex_panel_tfair .active-circular{
    color: #2943d6;
}
.ex_panel_tfair .table-wrapper{
    margin-bottom: 2rem;
}
.ex_panel_tfair .upcoming-fair {
    background: url(../../../../../../assets/images/fair-card-bg.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    position: relative;
}

.ex_panel_tfair .upcoming-fair .icon img {
    width: 100px;
}

.ex_panel_tfair .upcoming-fair .content {
    padding: 2rem;
    width: 100%;
    color: #fff;
    text-align: center;
}
.blink_me {
    animation: blinker 1.3s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
